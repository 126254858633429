import fontawesome from '@fortawesome/fontawesome';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas, faOtter } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { customArchiveProductScripts } from './modules/customArchiveProductScripts';
import {initNavigation} from "./modules/navigation";
import {initBlockSlider} from "../../includes/blocks/block-slider/script";
import {initCategoriesGallery} from "../../includes/blocks/block-categories-gallery/script";
import {initBlockReviews} from "../../includes/blocks/block-reviews/script";
import {initCollapseMenu} from "./modules/collapseMenu";
import {initWidgetFilterProducts} from "../../includes/widgets/widget-shop-filters/script";
import {initBlockProcedureList} from "../../includes/blocks/block-procedure-list/script";
import {productCustomGallery} from "./modules/productCustomGallery";
// import {productActiveScroll} from "./modules/customProductCardScripts";
// import {procedureActiveScroll} from "./modules/customProcedureScripts";
import {initBlockProcedureHeader} from "../../includes/blocks/block-procedure-header/script";
import {initBlockHero} from "../../includes/blocks/block-hero/script";
import {faqInSlider} from "./modules/faqInSlider";

fontawesome.library.add(far, fab, fas);

if(document.querySelector('.customArchiveProduct')) {
  customArchiveProductScripts();
}

if(document.querySelector('.headerMobile')){
  initNavigation();
}
if(document.querySelector('.customProductPage__gallery')){
  productCustomGallery();
}

if(document.querySelector('.faqInSlider')){
  faqInSlider();
}

// Blocks
if(document.querySelector('.blockHero')){
  initBlockHero();
}
if(document.querySelector('.blockSlider')){
  initBlockSlider();
}
if(document.querySelector('.blockCategoriesGallery')){
  initCategoriesGallery();
}
if(document.querySelector('.blockReviews')){
  initBlockReviews();
}
if(document.querySelector('.collapse__menu')){
  initCollapseMenu();
}
if(document.querySelector('.shopFiltersWidget')){
  initWidgetFilterProducts();
}
if(document.querySelector('.blockProcedureList')){
  initBlockProcedureList();
}
if(document.querySelector('.blockProcedureHeader')){
  initBlockProcedureHeader();
}
// if(document.querySelector('.customProductPage__summary')){
//   productActiveScroll();
// }
// if(document.querySelector('.pageProcedure__welcomeWrapper')){
//   procedureActiveScroll();
// }

/**
 * Disable WPCF7 button while it's submitting
 * Stops duplicate enquiries coming through
 */
if(document.querySelector('form.wpcf7-form')) {
  const formCF7 = document.querySelector('form.wpcf7-form');
  document.addEventListener( 'wpcf7submit', function( event ) {
      
    // find only disbaled submit buttons
    const button = formCF7.querySelector('.wpcf7-submit[disabled]');

    // grab the old value
    const old_value = button.getAttribute('data-value');

    // enable the button
    button.removeAttribute('disabled');

    // put the old value back in
    button.value = old_value;

  }, false );

  formCF7.addEventListener('submit',function() {
    if(formCF7.querySelector('.ajax-loader')) formCF7.querySelector('.ajax-loader').remove();

    const button = formCF7.querySelector('input[type=submit]');
    const current_val = button.value;

    // store the current value so we can reset it later
    button.setAttribute('data-value', current_val);

    // disable the button
    button.setAttribute("disabled", true);

    // tell the user what's happening
    button.value = "Wysyłanie...";

  });
}
