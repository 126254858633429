import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

export function faqInSlider() {
  document.addEventListener( 'DOMContentLoaded', function () {
    const faqInSlider = document.querySelectorAll('.faqInSlider');
    faqInSlider.forEach( element => {
      console.log(element)
      let secondarySlider = new Splide( element.querySelector('#secondary-faq-slider'), {
        type       : 'slide',
        gap        : 4,
        isNavigation: true,
        pagination : false,
        autoWidth: true,
        // arrows: false,
        breakpoints: {
          992: {
            // arrows: true,
            // direction  : 'ltr',
            // height     : 'auto',
          }
        }
      } );

      secondarySlider.on( 'overflow', function ( isOverflow ) {
        secondarySlider.options = {
          arrows    : isOverflow,
        };
      } );

      secondarySlider.mount();

      let primarySlider = new Splide( element.querySelector('#primary-faq-slider'), {
        type       : 'fade',
        pagination : false,
        arrows     : false,
      } );

      primarySlider.sync( secondarySlider ).mount();
    })

  });
}

