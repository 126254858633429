import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

export const initBlockHero = () => {
  document.addEventListener('DOMContentLoaded', function () {
    let sliderBlocks = document.querySelectorAll('.blockHero');

    if (sliderBlocks) {
      sliderBlocks.forEach(sliderBlock => {
        let sliderElem = sliderBlock.querySelector('.blockHero__sliderWrapper');

        const slider = new Splide(sliderElem, {
          type: "loop",
          perPage: 1,
          perMove: 1,
          pagination: false,
        });

        slider.on( 'overflow', function ( isOverflow ) {
          // Reset the carousel position
          slider.go( 0 );

          slider.options = {
            arrows    : isOverflow,
            pagination: false,
            drag      : isOverflow,
            clones    : isOverflow ? undefined : 0, // Toggle clones
          };
        } );

        slider.mount();
      });
    }
  });
}