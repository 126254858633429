import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
var SimpleLightbox = require('simple-lightbox');
import 'simple-lightbox/dist/simpleLightbox.css';

export function productCustomGallery() {
  document.addEventListener( 'DOMContentLoaded', function () {


    if( document.querySelector('#secondary-slider') ) {
      const primarySlider = new Splide( '#primary-slider', {
        type       : 'loop',
        pagination : false,
        arrows     : true,
      } );

      let secondarySlider = new Splide( '#secondary-slider', {
        fixedWidth : 72,
        fixedHeight: 72,
        height     : 456,
        gap        : 24,
        perPage    : 4,
        perMove    : 1,
        direction  : 'ttb',
        rewind     : true,
        cover      : true,
        isNavigation: true,
        pagination : false,
        arrows: false,
        breakpoints: {
          992: {
            arrows: true,
            direction  : 'ltr',
            height     : 'auto',
          }
        }
      } );

      secondarySlider.mount();
      primarySlider.sync( secondarySlider ).mount();
    } else {
      const primarySlider = new Splide( '#primary-slider', {
        type       : 'slider',
        pagination : false,
        arrows     : false,
        drag       : false
      } ).mount();
    }

    const lightbox = new SimpleLightbox({
      elements: '.lightBoxRun'
    });
  });
}

