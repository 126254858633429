import Splide from '@splidejs/splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

export const initBlockProcedureHeader = () => {
    document.addEventListener('DOMContentLoaded', function () {
        let sliderBlocks = document.querySelectorAll('.blockProcedureHeader');

        if (sliderBlocks) {
            sliderBlocks.forEach(sliderBlock => {
                let sliderElem = sliderBlock.querySelector('.blockProcedureHeader__slider');
                const slider = new Splide(sliderElem, {
                    type:       "loop",
                    perPage:    4,
                    perMove:    1,
                    arrows:     false,
                    pagination: true,
                    gap:        32,
                    breakpoints: {
                        1200: {
                            perPage: 3,
                        },
                        992: {
                            perPage: 2,
                        },
                        650: {
                            perPage: 1,
                        },
                        460: {
                            perPage: 1,
                        }
                    }
                });
                slider.on( 'overflow', function ( isOverflow ) {
                    // Reset the carousel position
                    slider.go( 0 );

                    slider.options = {
                        arrows    : false,
                        pagination: isOverflow,
                        drag      : isOverflow,
                        clones    : isOverflow ? undefined : 0, // Toggle clones
                    };
                } );

                slider.mount();
            });
        }
    });
}