const axios = require("axios");

export const initNavigation = () => {
    const menuDesktop = document.querySelector('.headerDesktop');
    const menuMobile = document.querySelector('.headerMobile');

    if(menuMobile){
        const burgerBtn = document.querySelector('.headerMobile__hamburger > button');
        const menu = menuMobile.querySelector('.headerMobile__mainMenu');
        const menuWrapper = menu.querySelector('.headerMobile__wrapper');
        const closeBtn = menu.querySelector('span.close');
        const mainMenu = menu.querySelector('.menu_mobile');
        const subMenus = menu.querySelectorAll(`.menu_mobile__submenu`);

        if(mainMenu){
            mainMenu.querySelectorAll('li').forEach(menuItem => {
                let btn = menuItem.querySelector('span.btn_open_menu');
                if(btn){
                    btn.addEventListener('click', () => {
                        let target = btn.getAttribute('data-open-submenu');
                        let submenu = document.querySelector(`.menu_mobile__submenu[data-submenu=${target}]`);
                        if(submenu){
                            submenu.classList.add('opened');
                            menuWrapper.style.height = `${submenu.clientHeight+1}px`;
                            mainMenu.style.display = "none";
                        }
                    });
                }
            });
        }
        if(subMenus){
            subMenus.forEach(submenu => {
                let close = submenu.querySelector('li.btn_back');
                close.addEventListener('click', () => {
                    submenu.classList.remove('opened');
                    menuWrapper.style.height = 'auto';
                    mainMenu.style.display = "block";
                });
            });
        }

        burgerBtn.addEventListener('click', () => {
            menu.classList.add('opened');
        });

        closeBtn.addEventListener('click', () => {
            menu.classList.remove('opened');
            setTimeout(() => {
                if(subMenus){
                    subMenus.forEach(submenu => {
                        submenu.classList.remove('opened');
                        menuWrapper.style.height = 'auto';
                        mainMenu.style.display = "block";
                    });
                }
            }, 500);
        });

        const menuMobileSearchOpen = menuMobile.querySelector('.searchboxButton');
        const menuMobileSearchClose = menuMobile.querySelector('.closeButton');
        const menuMobileSearchBox = menuMobile.querySelector('.headerMobile__searchBox');
        const menuMobileSearchBoxResult = menuMobile.querySelector('#searchResult');

        menuMobileSearchOpen.addEventListener('click', ()=> {
            menuMobileSearchBox.classList.add('open');
            menuMobileSearchBox.querySelector('#searchInput').focus();
        })

        menuMobileSearchClose.addEventListener('click', ()=> {
            menuMobileSearchBox.classList.remove('open');
            menuMobileSearchBox.querySelector('#searchInput').value = '';
            menuMobileSearchBoxResult.innerHTML = '';
        })

        const searchProductMobile = (keyword) => {
            menuMobileSearchBoxResult.innerHTML = '';
            menuMobileSearchBoxResult.innerHTML = '<div class="spinnerBox"><i class="fa-li fa fa-spinner fa-spin"></i></div>';

            axios
              .get(
                `${ajax.url}?action=search_product&keyword=${keyword}`
              )
              .then(response => {
                  const data = response.data;
                  menuMobileSearchBoxResult.innerHTML = '';

                  data.forEach(product => {
                      menuMobileSearchBoxResult.innerHTML += product.product;
                  })
              });
        }

        if(menuMobileSearchBox){
            const searchInput = menuMobileSearchBox.querySelector('#searchInput');
            const searchInterval = 500;
            let searchIntervalFlag;

            searchInput.addEventListener('keyup', event => {
                clearTimeout(searchIntervalFlag)
                searchIntervalFlag = setTimeout(startSearch, searchInterval);
            })

            searchInput.addEventListener('keydown', event => {
                clearTimeout(searchIntervalFlag)
            })

            const startSearch = () => {
                searchProductMobile(searchInput.value);
            }
        }
    }

    if(menuDesktop){
        document.addEventListener('scroll', () => {
            let scrollY = window.scrollY;
            if(scrollY > 0){
                menuDesktop.classList.add('scrolled');
            } else{
                menuDesktop.classList.remove('scrolled');
            }
        });

        const menuDesktopSearchOpen = menuDesktop.querySelector('.searchboxButton');
        const menuDesktopSearchClose = menuDesktop.querySelector('.closeButton');
        const menuDesktopSearchBox = menuDesktop.querySelector('.headerDesktop__searchBox');
        const menuDesktopSearchBoxResult = menuDesktop.querySelector('#searchResult');

        menuDesktopSearchOpen.addEventListener('click', ()=> {
            menuDesktopSearchBox.classList.add('open');
            menuDesktopSearchBox.querySelector('#searchInput').focus();
        })

        menuDesktopSearchClose.addEventListener('click', ()=> {
            menuDesktopSearchBox.classList.remove('open');
            menuDesktopSearchBox.querySelector('#searchInput').value = '';
            menuDesktopSearchBoxResult.innerHTML = '';
        })

        const searchProductDesktop = (keyword) => {
            menuDesktopSearchBoxResult.innerHTML = '';
            menuDesktopSearchBoxResult.innerHTML = '<div class="spinnerBox"><i class="fa-li fa fa-spinner fa-spin"></i></div>';

            axios
              .get(
                `${ajax.url}?action=search_product&keyword=${keyword}`
              )
              .then(response => {
                  const data = response.data;
                  menuDesktopSearchBoxResult.innerHTML = '';

                  data.forEach(product => {
                      menuDesktopSearchBoxResult.innerHTML += product.product;
                  })
              });
        }

        if(menuDesktopSearchBox){
            const searchInput = menuDesktopSearchBox.querySelector('#searchInput');
            const searchInterval = 500;
            let searchIntervalFlag;

            searchInput.addEventListener('keyup', event => {
                clearTimeout(searchIntervalFlag)
                searchIntervalFlag = setTimeout(startSearch, searchInterval);
            })

            searchInput.addEventListener('keydown', event => {
                clearTimeout(searchIntervalFlag)
            })

            const startSearch = () => {
                searchProductDesktop(searchInput.value);
            }
        }
    }
}